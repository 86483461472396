import { createSelector } from "reselect";
import { GroupState, TreeNode } from "../reducers/groups";
import { findGroupById, getPathToGroup } from "../utils/group";
import { userStateSelector } from "./users";

export type EffectiveGroups =
  // The user has selected one group from the UI
  | {
      type: "single";
      selectedGroup: TreeNode;
    }
  // The user has not selected a group from the UI; his root groups are considered to be implicitely selected
  | {
      type: "root";
      hierarchies: TreeNode[];
    };

export const groupStateSelector = (state: any): GroupState => state.groups;
export const groupHierarchiesSelector = createSelector(
  groupStateSelector,
  (groups) => groups.hierarchies
);

/**
 * Selected group is the group selected by the user from `GroupSelectionPopup`.
 */
export const selectedGroupIdSelector = createSelector(
  groupStateSelector,
  (state) => state.selectedGroupId
);

export const selectedGroupSelector = createSelector(
  groupHierarchiesSelector,
  selectedGroupIdSelector,
  (hierarchy, selectedGroupId) =>
    selectedGroupId !== undefined
      ? findGroupById(hierarchy, selectedGroupId)
      : undefined
);

/**
 * Effective groups are the groups whose devices are currently visible. This can be:
 *
 * 1. The group selected by the user from `GroupSelectionPopup`
 * 2. The root groups of the user's hierarchy (= the groups the user belongs to), if the user
 *    hasn't selected a specific group.
 */
export const effectiveGroupsSelector = createSelector<
  any,
  TreeNode | undefined,
  TreeNode[],
  EffectiveGroups
>(
  selectedGroupSelector,
  groupHierarchiesSelector,
  (selectedGroup, hierarchies) =>
    selectedGroup !== undefined
      ? { type: "single", selectedGroup }
      : { type: "root", hierarchies }
);

/**
 * Return a single effective group.
 *
 * If the user belongs to multiple groups and has not selected a group, this function returns null;
 * otherwise (the user belongs to one group only, or the user has selected a group), this single
 * group is returned.
 *
 * This function is useful for scale pages, which need to have a single selected group.
 */
export const singleEffectiveGroupSelector = createSelector(
  effectiveGroupsSelector,
  (effectiveGroups) => {
    if (effectiveGroups.type === "single") {
      return effectiveGroups.selectedGroup;
    } else if (
      effectiveGroups.type === "root" &&
      effectiveGroups.hierarchies.length === 1
    ) {
      return effectiveGroups.hierarchies[0];
    }
    return null;
  }
);

/**
 * Return the group we should display to the user in data analytics.
 */
export const singleEffectiveAnalyticsGroupSelector = createSelector(
  singleEffectiveGroupSelector,
  groupHierarchiesSelector,
  userStateSelector,
  (effectiveGroup, hierarchy, { primaryAnalyticsGroup }) => {
    if (!primaryAnalyticsGroup) {
      return undefined;
    }

    const primaryAnalyticsTreeNode = findGroupById(
      hierarchy,
      primaryAnalyticsGroup.id
    );
    if (!primaryAnalyticsTreeNode) {
      return undefined;
    }

    const isEffectiveGroupVisibleToPrimaryAnalyticsGroup =
      effectiveGroup &&
      findGroupById([primaryAnalyticsTreeNode], effectiveGroup.groupId);
    if (isEffectiveGroupVisibleToPrimaryAnalyticsGroup) {
      return effectiveGroup;
    }

    return primaryAnalyticsTreeNode;
  }
);

export const selectedGroupNamePathSelector = createSelector(
  groupHierarchiesSelector,
  selectedGroupIdSelector,
  (hierarchies, selectedGroupId) => {
    if (selectedGroupId === undefined && hierarchies.length === 1) {
      return [hierarchies[0].name];
    } else if (selectedGroupId !== undefined) {
      return getPathToGroup(hierarchies, selectedGroupId).map(
        (group) => group.name
      );
    }
    return [];
  }
);
