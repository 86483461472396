import {
  DeviceType,
  DeviceStatus,
  FillState,
  SelectableColumns,
  MachineType,
} from "../interfaces/types";
import icon_status_ok from "../assets/icon-status-ok.svg";
import icon_status_warning from "../assets/icon-status-warning.svg";
import icon_status_error from "../assets/icon-status-error.svg";
import icon_status_intransit from "../assets/icon-status-intransit.svg";
import icon_power_off from "../assets/icon-status-off-selected.svg";
import i18next from "i18next";
import { getPredictedFillUp } from "../components/Device";
import { format, setDay } from "date-fns";
import en from "date-fns/locale/en-US";
import { getPrimaryStatus, getStatusMessage } from "./deviceStatus";
/**
 * Returns true if token has expired false if not
 * Note! this function returns about 60 s before token actually expires this is for that reason
 * that we can do user logout with working token.
 * @export
 * @param {*} token state object
 * @returns {boolean}
 */
export function isTokenExpired(token: any): boolean {
  const timeMargin = 60 * 1000;
  if (token && token.key !== "" && token.expiresIn !== 0) {
    return Date.now() + timeMargin > token.expiresIn;
  }
  return true;
}
/**
 * Converts on given devices array to corresponding
 * more strict device type
 *
 * @export
 * @param {any[]} devices is raw devices array which is got from backend
 * @returns {Array<DeviceType>}
 */
export function convertToDeviceType(devices: any[]): Array<DeviceType> {
  return devices.map((device) => {
    let homeLatitude: number | undefined = stringToNumber(device.homeLatitude);
    let homeLongitude: number | undefined = stringToNumber(
      device.homeLongitude
    );
    let latitude: number | undefined = stringToNumber(device.latitude);
    let longitude: number | undefined = stringToNumber(device.longitude);
    return {
      ...device,
      homeLatitude: homeLatitude,
      homeLongitude: homeLongitude,
      latitude: latitude,
      longitude: longitude,
    };
  });
}
function stringToNumber(str: string): number | undefined {
  const num = parseFloat(str);
  return isNaN(num) ? undefined : num;
}
/**
 * Filter devices based on a query string which can contain boolean variables AND, OR, NOT, and be grouped with ().
 */
export function filterDevicesByString(
  devices: Array<DeviceType>,
  query: string
): Array<DeviceType> {
  // Algorithm from here:  https://github.com/jin-zhe/boolean-retrieval-engine/blob/master/search.py
  let q = query.replace(/\(/gi, "( ");
  q = q.replace(/\)/gi, " )");
  let terms = q
    .toLowerCase()
    .trim()
    .split(" ")
    .map((term) => term.trim())
    .filter((term) => {
      return term !== "";
    });
  let tokens: Array<string> = [];
  for (let i = 0; i < terms.length; ++i) {
    if (
      terms[i] === "and" ||
      terms[i] === "or" ||
      terms[i] === "not" ||
      terms[i] === "(" ||
      terms[i] === ")"
    ) {
      tokens.push(terms[i]);
      continue;
    } else {
      tokens.push(terms[i]);
      // Peek next term - if there is not boolean variable add "AND"
      if (i + 1 < terms.length) {
        const term = terms[i + 1];
        if (
          term !== "and" &&
          term !== "or" &&
          term !== "not" &&
          term !== ")" &&
          term !== "("
        ) {
          tokens.push("and");
        }
      }
    }
  }
  terms = shuntingYard(tokens);
  const results_stack = [];
  for (const token of terms) {
    let result: any[] = [];
    if (token !== "and" && token !== "or" && token !== "not") {
      result = devices.filter((device) => {
        return isStringOnDevice(device, token);
      });
    } else if (token === "and") {
      let right_operand = results_stack.pop();
      let left_operand = results_stack.pop();
      if (right_operand && left_operand) {
        result = applyOperatorAND(left_operand, right_operand);
      }
    } else if (token === "or") {
      let right_operand = results_stack.pop();
      let left_operand = results_stack.pop();
      if (right_operand && left_operand) {
        result = applyOperatorOR(left_operand, right_operand);
      }
    } else if (token === "not") {
      let right_operand = results_stack.pop();
      let left_operand = results_stack.pop();
      if (right_operand && left_operand) {
        result = applyOperatorNOT(left_operand, right_operand);
      }
    }
    results_stack.push(result);
  }
  if (results_stack.length === 1) {
    return results_stack[0];
  }
  return [];
}
/**
 * Implements Shunting-Yard algorithm for reorganizing operators and operands
 * https://en.wikipedia.org/wiki/Shunting-yard_algorithm
 */
export function shuntingYard(tokens: string[]): Array<any> {
  const precedence = new Map<string, number>();
  precedence.set("not", 3);
  precedence.set("and", 2);
  precedence.set("or", 1);
  precedence.set("(", 0);
  precedence.set(")", 0);
  const output = [];
  const operator_stack: Array<string> = [];
  for (const token of tokens) {
    // if left bracket
    if (token === "(") {
      operator_stack.push(token);
    } else if (token === ")") {
      // if right bracket, pop all operators from operator stack onto output until we hit left bracket
      let operator: string | undefined = operator_stack.pop();
      while (operator && operator !== "(") {
        output.push(operator);
        operator = operator_stack.pop();
      }
    } // if operator, pop operators from operator stack to queue if they are of higher precedence
    else if (precedence.get(token)) {
      // if operator stack is not empty
      if (operator_stack.length > 0) {
        let current_operator = operator_stack[operator_stack.length - 1];
        let currOperatorValue = precedence.get(current_operator);
        let tokenOperatorValue = precedence.get(token);
        while (
          operator_stack.length > 0 &&
          currOperatorValue &&
          tokenOperatorValue &&
          currOperatorValue > tokenOperatorValue
        ) {
          output.push(operator_stack.pop());
          if (operator_stack.length > 0) {
            current_operator = operator_stack[operator_stack.length - 1];
          }
        }
      }
      // Add token to operator stack
      operator_stack.push(token);
    } else {
      // else if operands, add to output list
      output.push(token);
    }
  }
  //while there are still operators on the stack, pop them into the queue
  while (operator_stack.length > 0) {
    output.push(operator_stack.pop());
  }
  return output;
}

function applyOperatorOR(
  left: Array<DeviceType>,
  right: Array<DeviceType>
): Array<DeviceType> {
  const result = [...left, ...right];
  return [...new Set(result)];
}

function applyOperatorAND(
  left: Array<DeviceType>,
  right: Array<DeviceType>
): Array<DeviceType> {
  // Merge lists so that there exist only unique devices
  const result: Array<DeviceType> = [];
  if (left.length >= right.length) {
    for (const deviceLeft of left) {
      for (const deviceRight of right) {
        if (deviceLeft.serialNumber === deviceRight.serialNumber) {
          result.push(deviceLeft);
          break;
        }
      }
    }
  } else {
    for (const deviceRight of right) {
      for (const deviceLeft of left) {
        if (deviceLeft.serialNumber === deviceRight.serialNumber) {
          result.push(deviceRight);
          break;
        }
      }
    }
  }
  // Remove dublicates
  return [...new Set(result)];
}

function applyOperatorNOT(
  left: Array<DeviceType>,
  right: Array<DeviceType>
): Array<DeviceType> {
  const result: Array<DeviceType> = left.filter((device) => {
    for (const d of right) {
      if (d.serialNumber === device.serialNumber) {
        return false;
      }
    }
    return true;
  });
  return result;
}

function isStringOnDevice(device: DeviceType, searchToken: string): boolean {
  const parentGroupNames: string[] =
    device.parentGroups?.map((group) => group?.name) ?? [];

  const deviceTokens = [
    device.alias,
    device.serialNumber,
    device.wasteFraction,
    device.customerName,
    device.site,
    device.ownerGroup,
    device.operatorGroup,
    ...parentGroupNames,
  ];

  return deviceTokens.some(
    (token) => token && token.toLowerCase().includes(searchToken.toLowerCase())
  );
}

/**
 * Filteres devices which belongs to given group
 * @param devices all devices
 * @param group which devices we want to return
 */
export function filterDevicesByGroup(devices: any[], group: any): Array<any> {
  const childrenGuids = group.children.map((child: any) => {
    return child.guid;
  });
  let filteredDevices = devices.filter((device) => {
    if (device.ownerGroup && device.ownerGroupGuid === group.guid) {
      return true;
    }

    if (device.operatorGroup && device.operatorGroupGuid) {
      // There exist a corner case that if device is connected to operator group and
      // that operator group is a subgroup of searched param group it causes that we need
      // to go also param groups children and check that is that device operator group guid one of them
      if (device.operatorGroupGuid === group.guid) {
        return true;
      }
      for (const guid of childrenGuids) {
        if (device.operatorGroupGuid === guid) {
          return true;
        }
      }
    }

    if (device.parentGroups) {
      for (const parent of device.parentGroups) {
        if (parent && parent.guid === group.guid) {
          return true;
        }
      }
    }
    return false;
  });
  return filteredDevices;
}

export function fillLevelToString(state: FillState): string {
  switch (state) {
    case FillState.Empty: {
      return i18next.t("fillState.empty", "Empty");
    }
    case FillState.Level_1: {
      return i18next.t("fillState.level1", "Level 1");
    }
    case FillState.Level_2: {
      return i18next.t("fillState.level2", "Level 2");
    }
    case FillState.Full: {
      return i18next.t("fillState.full", "Full");
    }
    case FillState.AlmostFull: {
      return i18next.t("fillState.almostFull", "Almost full");
    }
    case FillState.OverFull: {
      return i18next.t("fillState.overfull", "Overfull");
    }
    case FillState.Unknown: {
      return i18next.t("fillState.unknown", "Unknown");
    }
    default: {
      return i18next.t("fillState.default", "Unknown");
    }
  }
}
/**
 * Uses BFS search to flat a group hierarchy to array which indice tells group
 * level in group hierarchy tree
 */
export function flatGroupHierarchyUsingBFSSearchToList(groups: any): any[] {
  let children: any[] = [];
  if (groups) {
    let queue = [];
    queue.push(groups[0]);
    queue.push(null);
    let element = undefined;
    let depth = 0;
    while (queue.length > 0) {
      element = queue.shift();
      if (element === null) {
        if (queue.length !== 0) {
          queue.push(null);
          depth = depth + 1;
        }
        continue;
      }
      if (children.length === depth + 1) {
        children[depth].push(element);
      } else {
        children.push([element]);
      }
      if (element.children.length === 0) {
        continue;
      }
      for (let i = 0; i < element.children.length; ++i) {
        queue.push(element.children[i]);
      }
    }
  }
  return children;
}

export function getStatusIcon(status: DeviceStatus): any {
  switch (status) {
    case DeviceStatus.OK: {
      return icon_status_ok;
    }
    case DeviceStatus.Error: {
      return icon_status_error;
    }
    case DeviceStatus.InTransit: {
      return icon_status_intransit;
    }
    case DeviceStatus.PowerOff: {
      return icon_power_off;
    }
    case DeviceStatus.Warning: {
      return icon_status_warning;
    }
  }
}
export function getFillLevelAsString(fillState: FillState | undefined): string {
  if (fillState === undefined) {
    return "?";
  }
  let fillLevel: string = i18next.t("fillLevel.default", "?");
  switch (fillState) {
    case FillState.Empty: {
      fillLevel = i18next.t("fillLevel.empty", "0");
      break;
    }
    case FillState.Level_1: {
      fillLevel = i18next.t("fillLevel.level1", "1");
      break;
    }
    case FillState.Level_2: {
      fillLevel = i18next.t("fillLevel.level2", "2");
      break;
    }
    case FillState.AlmostFull: {
      fillLevel = i18next.t("fillLevel.almostFull", "3");
      break;
    }
    case FillState.Full: {
      fillLevel = i18next.t("fillLevel.full", "F");
      break;
    }
    case FillState.OverFull: {
      fillLevel = i18next.t("fillLevel.overFull", "X");
      break;
    }
    default: {
      break;
    }
  }
  return fillLevel;
}
/**
 * Comparing function - can be used to sort dates. Returning order is descending order
 * means newest is first.
 * @param left date
 * @param right date
 */
export function compareDatesDesc(left: Date, right: Date): number {
  const diff = left.getTime() - right.getTime();
  if (diff > 0) {
    return -1;
  } else if (diff < 0) {
    return 1;
  } else {
    // Returns 0 when diff is 0 and NaN when it is NaN.
    return diff;
  }
}
/**
 * Comparing function - can be used to sort dates. Returning order is ascending order
 * means oldest is first.
 * @param left date
 * @param right date
 */
export function compareDatesAsc(left: Date, right: Date): number {
  const diff = right.getTime() - left.getTime();
  if (diff > 0) {
    return -1;
  } else if (diff < 0) {
    return 1;
  } else {
    // Returns 0 when diff is 0 and NaN when it is NaN.
    return diff;
  }
}
export function compareDates(left: Date, right: Date, order: number): number {
  if (order > 0) {
    return compareDatesDesc(left, right);
  }
  return compareDatesAsc(left, right);
}

export function columnSortable(
  device: DeviceType,
  column: SelectableColumns,
  forEuropress: boolean
): boolean {
  let sortable: boolean = false;
  switch (column) {
    case SelectableColumns.STATUS: {
      sortable = true;
      break;
    }
    case SelectableColumns.STATUS_MESSAGE: {
      const text = getStatusMessage(device, forEuropress);
      sortable = text !== null;
      break;
    }
    case SelectableColumns.SERIAL_NUMBER: {
      if (device.machineType && device.machineType === MachineType.Skip) {
        return false;
      }
      sortable = device.serialNumber !== undefined ? true : false;
      break;
    }
    case SelectableColumns.ASSET_NAME: {
      sortable =
        device.alias !== "" &&
        device.alias !== undefined &&
        device.alias !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.GROUP: {
      sortable =
        device.ownerGroup !== "" && device.ownerGroup !== undefined
          ? true
          : false;
      break;
    }
    case SelectableColumns.OWNER_GROUP_UPDATED_AT: {
      sortable =
        device.ownerGroupUpdatedAt !== null &&
        device.ownerGroupUpdatedAt !== undefined &&
        device.ownerGroupUpdatedAt !== "";
      break;
    }
    case SelectableColumns.FILL_LEVEL: {
      sortable =
        device.fillLevel !== undefined && device.fillLevel !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.WASTE_FRACTION: {
      let wasteFraction = device.wasteFraction
        ? device.wasteFraction
        : device.wasteFractionAlternative;
      sortable =
        wasteFraction !== undefined &&
        wasteFraction !== "" &&
        wasteFraction !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.PREDICTED_FILL_UP:
      sortable = getPredictedFillUp(device) != null;
      break;
    case SelectableColumns.LAST_EMPTIED: {
      sortable =
        device.lastEmptyingDate !== undefined &&
        device.lastEmptyingDate !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.SITE: {
      sortable =
        device.site !== "" && device.site !== undefined && device.site !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.ONLINE: {
      sortable =
        device.onlineNow !== undefined && device.onlineNow !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.OPERATOR_GROUP: {
      sortable =
        device.operatorGroup !== "" &&
        device.operatorGroup !== undefined &&
        device.operatorGroup !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.OPERATOR_GROUP_UPDATED_AT: {
      sortable =
        device.operatorGroupUpdatedAt !== null &&
        device.operatorGroupUpdatedAt !== undefined &&
        device.operatorGroupUpdatedAt !== "";
      break;
    }
    case SelectableColumns.EQUIPMENT_CODE: {
      sortable =
        device.equipmentCode !== "" && device.equipmentCode !== undefined
          ? true
          : false;
      break;
    }
    case SelectableColumns.LAST_ONLINE: {
      sortable =
        device.lastOnline !== undefined && device.lastOnline !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.SIGNAL_QUALITY_RSSI: {
      sortable = device.signalQualityRSSI !== undefined ? true : false;
      break;
    }
    case SelectableColumns.PHONE_NO: {
      sortable =
        device.phoneNumber !== "" &&
        device.phoneNumber !== undefined &&
        device.phoneNumber !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.SERVICE_IS_DUE: {
      sortable =
        device.serviceIsDue !== undefined && device.serviceIsDue !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.CONTROLLER: {
      sortable =
        device.controllerTypeId !== undefined &&
        device.controllerTypeId !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.HW_REV: {
      sortable =
        device.controllerPcbHwRevision !== "" &&
        device.controllerPcbHwRevision !== undefined &&
        device.controllerPcbHwRevision !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.F_W: {
      sortable =
        device.firmwareId !== undefined && device.firmwareId !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.LOCATION: {
      sortable = true;
      break;
    }
    case SelectableColumns.PIC: {
      sortable = false;
      break;
    }
    case SelectableColumns.CUSTOMER: {
      sortable =
        device.customerName !== "" &&
        device.customerName !== undefined &&
        device.customerName !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.RENTAL_ADDRESS: {
      sortable =
        device.rentalAddress !== "" &&
        device.rentalAddress !== undefined &&
        device.rentalAddress !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.RENTAL_PERIOD: {
      sortable =
        device.rentalStartDate !== undefined && device.rentalStartDate !== null
          ? true
          : false;
      break;
    }
    case SelectableColumns.BALE_COUNTER: {
      sortable =
        device.baleCounter !== null && device.baleCounter !== undefined;
      break;
    }
    case SelectableColumns.BATTERY_LEVEL: {
      sortable =
        device.batteryLevel !== null && device.batteryLevel !== undefined;
      break;
    }
    default: {
      sortable = false;
      break;
    }
  }
  return sortable;
}

export function compareDevicesBySortColumn(
  left: DeviceType,
  right: DeviceType,
  order: number,
  sortColumn: SelectableColumns,
  forEuropress: boolean
): number {
  let l: any = undefined;
  let r: any = undefined;
  switch (sortColumn) {
    case SelectableColumns.STATUS: {
      l = getPrimaryStatus(left);
      r = getPrimaryStatus(right);
      break;
    }
    case SelectableColumns.STATUS_MESSAGE: {
      // These are non-null because of the check in `columnSortable`
      let leftError = getStatusMessage(left, forEuropress)!;
      let rightError = getStatusMessage(right, forEuropress)!;
      if (order > 0) {
        return leftError.localeCompare(rightError);
      } else {
        return rightError.localeCompare(leftError);
      }
    }
    case SelectableColumns.SERIAL_NUMBER: {
      let sortOrder = -1;

      if (left.serialNumber > right.serialNumber) {
        sortOrder = 1;
      } else if (left.serialNumber === right.serialNumber) {
        sortOrder = 0;
      }

      return 0 < order ? sortOrder : -1 * sortOrder;
    }
    case SelectableColumns.ASSET_NAME: {
      if (left.alias && right.alias) {
        if (order > 0) {
          return left.alias.localeCompare(right.alias);
        } else {
          return right.alias.localeCompare(left.alias);
        }
      }
      return 0;
    }
    case SelectableColumns.GROUP: {
      if (left.ownerGroup && right.ownerGroup) {
        if (order > 0) {
          return left.ownerGroup.localeCompare(right.ownerGroup);
        } else {
          return right.ownerGroup.localeCompare(left.ownerGroup);
        }
      }
      return 0;
    }
    case SelectableColumns.OWNER_GROUP_UPDATED_AT: {
      if (left.ownerGroupUpdatedAt && right.ownerGroupUpdatedAt) {
        return compareDates(
          new Date(left.ownerGroupUpdatedAt),
          new Date(right.ownerGroupUpdatedAt),
          order
        );
      }
      return 0;
    }
    case SelectableColumns.FILL_LEVEL: {
      const leftUsePressureCurve =
        left.fillLevelMode === 1 && left.pressureCurveFillPercent !== null;
      const rightUsePressureCurve =
        right.fillLevelMode === 1 && right.pressureCurveFillPercent !== null;

      // Devices without pressureCurveFillPercent will use these values instead
      const fillLevelSortValues: { [key: string]: number } = {
        [FillState.Unknown]: -1,
        [FillState.Empty]: 0,
        [FillState.Level_1]: 40,
        [FillState.Level_2]: 60,
        [FillState.AlmostFull]: 80,
        [FillState.Full]: 100,
        [FillState.OverFull]: 110,
      };

      l = leftUsePressureCurve
        ? left.pressureCurveFillPercent
        : fillLevelSortValues[Number(left.fillLevel)];
      r = rightUsePressureCurve
        ? right.pressureCurveFillPercent
        : fillLevelSortValues[Number(right.fillLevel)];

      break;
    }
    case SelectableColumns.WASTE_FRACTION: {
      let leftWasteFraction = left.wasteFraction
        ? left.wasteFraction
        : left.wasteFractionAlternative;
      let rightWasteFaction = right.wasteFraction
        ? right.wasteFraction
        : right.wasteFractionAlternative;
      if (leftWasteFraction && rightWasteFaction) {
        if (order > 0) {
          return leftWasteFraction.localeCompare(rightWasteFaction);
        } else {
          return rightWasteFaction.localeCompare(leftWasteFraction);
        }
      }
      return 0;
    }
    case SelectableColumns.PREDICTED_FILL_UP: {
      const leftPredicted = getPredictedFillUp(left)?.fillUpDays;
      const rightPredicted = getPredictedFillUp(right)?.fillUpDays;

      return order > 0
        ? leftPredicted! - rightPredicted!
        : rightPredicted! - leftPredicted!;
    }
    case SelectableColumns.LAST_EMPTIED: {
      if (left.lastEmptyingDate && right.lastEmptyingDate) {
        return compareDates(
          new Date(left.lastEmptyingDate),
          new Date(right.lastEmptyingDate),
          order
        );
      }
      return 0;
    }
    case SelectableColumns.SITE: {
      if (left.site && right.site) {
        if (order > 0) {
          return left.site.localeCompare(right.site);
        } else {
          return right.site.localeCompare(left.site);
        }
      }
      return 0;
    }
    case SelectableColumns.ONLINE: {
      l = Number(left.onlineNow);
      r = Number(right.onlineNow);
      break;
    }
    case SelectableColumns.OPERATOR_GROUP: {
      if (left.operatorGroup && right.operatorGroup) {
        if (order > 0) {
          return left.operatorGroup.localeCompare(right.operatorGroup);
        } else {
          return right.operatorGroup.localeCompare(left.operatorGroup);
        }
      }
      return 0;
    }
    case SelectableColumns.OPERATOR_GROUP_UPDATED_AT: {
      if (left.operatorGroupUpdatedAt && right.operatorGroupUpdatedAt) {
        return compareDates(
          new Date(left.operatorGroupUpdatedAt),
          new Date(right.operatorGroupUpdatedAt),
          order
        );
      }
      return 0;
    }
    case SelectableColumns.EQUIPMENT_CODE: {
      if (left.equipmentCode && right.equipmentCode) {
        if (order > 0) {
          return left.equipmentCode.localeCompare(right.equipmentCode);
        } else {
          return right.equipmentCode.localeCompare(left.equipmentCode);
        }
      }
      return 0;
    }
    case SelectableColumns.LAST_ONLINE: {
      if (left.lastOnline && right.lastOnline) {
        return compareDates(
          new Date(left.lastOnline),
          new Date(right.lastOnline),
          order
        );
      }
      return 0;
    }
    case SelectableColumns.SIGNAL_QUALITY_RSSI: {
      l = left.signalQualityRSSI;
      r = right.signalQualityRSSI;
      break;
    }
    case SelectableColumns.PHONE_NO: {
      l = Number(left.phoneNumber);
      r = Number(right.phoneNumber);
      break;
    }
    case SelectableColumns.SERVICE_IS_DUE: {
      l = Number(left.serviceIsDue);
      r = Number(right.serviceIsDue);
      break;
    }
    case SelectableColumns.CONTROLLER: {
      l = left.controllerTypeId;
      r = right.controllerTypeId;
      break;
    }
    case SelectableColumns.HW_REV: {
      if (left.controllerPcbHwRevision && right.controllerPcbHwRevision) {
        if (order > 0) {
          return left.controllerPcbHwRevision.localeCompare(
            right.controllerPcbHwRevision
          );
        } else {
          return right.controllerPcbHwRevision.localeCompare(
            left.controllerPcbHwRevision
          );
        }
      }
      return 0;
    }
    case SelectableColumns.F_W: {
      l = left.firmwareId;
      r = right.firmwareId;
      break;
    }
    case SelectableColumns.LOCATION: {
      const leftLatLng = getLatLng(left);
      const rightLatLng = getLatLng(right);

      let sortOrder = -1;
      // Order possible null values first in ascending sort
      if (leftLatLng === null || rightLatLng === null) {
        if (leftLatLng !== null && rightLatLng === null) {
          sortOrder = 1;
        } else if (leftLatLng === null && rightLatLng === null) {
          sortOrder = 0;
        }
      } else {
        // Primarily compare by latitude, fallback to longitude if latitudes are equal
        const cmpValues =
          leftLatLng.latitude !== rightLatLng.latitude
            ? [leftLatLng.latitude, rightLatLng.latitude]
            : [leftLatLng.longitude, rightLatLng.longitude];

        if (cmpValues[0] > cmpValues[1]) {
          sortOrder = 1;
        } else if (cmpValues[0] === cmpValues[1]) {
          sortOrder = 0;
        }
      }

      return 0 < order ? sortOrder : -1 * sortOrder;
    }
    case SelectableColumns.PIC: {
      // No sortable
      return 0;
    }
    case SelectableColumns.CUSTOMER: {
      if (left.customerName && right.customerName) {
        if (order > 0) {
          return left.customerName.localeCompare(right.customerName);
        } else {
          return right.customerName.localeCompare(left.customerName);
        }
      }
      return 0;
    }
    case SelectableColumns.RENTAL_ADDRESS: {
      if (left.rentalAddress && right.rentalAddress) {
        if (order > 0) {
          return left.rentalAddress.localeCompare(right.rentalAddress);
        } else {
          return right.rentalAddress.localeCompare(left.rentalAddress);
        }
      }
      return 0;
    }
    case SelectableColumns.RENTAL_PERIOD: {
      if (left.rentalStartDate && right.rentalStartDate) {
        return compareDates(
          new Date(left.rentalStartDate),
          new Date(right.rentalStartDate),
          order
        );
      }
      return 0;
    }
    case SelectableColumns.BALE_COUNTER: {
      l = left.baleCounter?.balesReady ?? 0;
      r = right.baleCounter?.balesReady ?? 0;
      break;
    }
    case SelectableColumns.BATTERY_LEVEL: {
      l = left.batteryLevel ?? 0;
      r = right.batteryLevel ?? 0;
      break;
    }
    default: {
      break;
    }
  }
  if (order > 0) {
    // Sort order is now [1,2,3,4]
    if (!isNaN(l) && !isNaN(r)) {
      const res = l - r;
      if (res > 0) {
        return 1;
      } else if (res < 0) {
        return -1;
      }
    }
  } else {
    // Sort order is now [4,3,2,1]
    if (!isNaN(l) && !isNaN(r)) {
      const res = r - l;
      if (res > 0) {
        return 1;
      } else if (res < 0) {
        return -1;
      }
    }
  }
  return 0;
}
/**
 * Converts selection column index to column name
 */
export function deviceColumnToString(index: number): string {
  let columnName = "";
  switch (index) {
    case SelectableColumns.STATUS: {
      columnName = i18next.t("column.status", "STATUS");
      break;
    }
    case SelectableColumns.ASSET_NAME: {
      columnName = i18next.t("column.assetName", "ASSET NAME");
      break;
    }
    case SelectableColumns.SERIAL_NUMBER: {
      columnName = i18next.t("column.serialNumber", "SERIAL NUMBER");
      break;
    }
    case SelectableColumns.GROUP: {
      columnName = i18next.t("column.group", "GROUP");
      break;
    }
    case SelectableColumns.OWNER_GROUP_UPDATED_AT: {
      columnName = i18next.t("column.ownerGroupUpdatedAt", "LAST GROUP CHANGE");
      break;
    }
    case SelectableColumns.FILL_LEVEL: {
      columnName = i18next.t("column.fillLevel", "FILL LEVEL");
      break;
    }
    case SelectableColumns.WASTE_FRACTION: {
      columnName = i18next.t("column.wasteFraction", "WASTE FRACTION");
      break;
    }
    case SelectableColumns.PREDICTED_FILL_UP: {
      columnName = i18next.t("column.predictedFillUp", "PREDICTED FILL UP");
      break;
    }
    case SelectableColumns.LAST_EMPTIED: {
      columnName = i18next.t("column.lastEmptied", "LAST EMPTIED");
      break;
    }
    case SelectableColumns.SITE: {
      columnName = i18next.t("column.site", "SITE");
      break;
    }
    case SelectableColumns.ONLINE: {
      columnName = i18next.t("column.online", "ONLINE");
      break;
    }
    case SelectableColumns.OPERATOR_GROUP: {
      columnName = i18next.t("column.operatorGroup", "OPERATOR GROUP");
      break;
    }
    case SelectableColumns.OPERATOR_GROUP_UPDATED_AT: {
      columnName = i18next.t(
        "column.operatorGroupUpdatedAt",
        "LAST OPERATOR CHANGE"
      );
      break;
    }
    case SelectableColumns.EQUIPMENT_CODE: {
      columnName = i18next.t("column.equipmentCode", "EQUIPMENT CODE");
      break;
    }
    case SelectableColumns.LAST_ONLINE: {
      columnName = i18next.t("column.lastOnline", "LAST ONLINE");
      break;
    }
    case SelectableColumns.SIGNAL_QUALITY_RSSI: {
      columnName = i18next.t("column.signalQualityRssi", "SIGNAL QUALITY RSSI");
      break;
    }
    case SelectableColumns.PHONE_NO: {
      columnName = i18next.t("column.phoneNumber", "PHONE NUMBER");
      break;
    }
    case SelectableColumns.SERVICE_IS_DUE: {
      columnName = i18next.t("column.serviceIsDue", "SERVICE IS DUE");
      break;
    }
    case SelectableColumns.CONTROLLER: {
      columnName = i18next.t("column.controller", "CONTROLLER");
      break;
    }
    case SelectableColumns.HW_REV: {
      columnName = i18next.t("column.hwRevision", "HW REVISION");
      break;
    }
    case SelectableColumns.F_W: {
      columnName = i18next.t("column.F/W", "F/W");
      break;
    }
    case SelectableColumns.LOCATION: {
      columnName = i18next.t("column.location", "LOCATION");
      break;
    }
    case SelectableColumns.STATUS_MESSAGE: {
      columnName = i18next.t("column.statusMessage", "STATUS MESSAGE");
      break;
    }
    case SelectableColumns.PIC: {
      columnName = i18next.t("column.pic", "PIC");
      break;
    }
    case SelectableColumns.CUSTOMER: {
      columnName = i18next.t("column.customer", "CUSTOMER");
      break;
    }
    case SelectableColumns.RENTAL_ADDRESS: {
      columnName = i18next.t("column.rentalAddress", "RENTAL ADDRESS");
      break;
    }
    case SelectableColumns.RENTAL_PERIOD: {
      columnName = i18next.t("column.rentalPeriod", "RENTAL PERIOD");
      break;
    }
    case SelectableColumns.ADDRESS: {
      columnName = i18next.t("column.address", "ADDRESS");
      break;
    }
    case SelectableColumns.BALE_COUNTER:
      columnName = i18next.t("column.bale_counter", "BALES READY");
      break;
    case SelectableColumns.BATTERY_LEVEL:
      columnName = i18next.t("column.battery_level", "BATTERY LEVEL");
      break;
    default: {
      break;
    }
  }
  return columnName;
}

export function arrayToIdObject<T extends { id: number }>(data: T[]) {
  return Object.fromEntries(data.map((elem) => [elem.id, elem]));
}

/**
 * Convert a weekday (0 - 7) to a string.
 */
export function weekdayToString(
  weekday: number,
  stringFormat: string = "ccc",
  locale = en
) {
  return format(setDay(new Date(), weekday), stringFormat, { locale });
}

export function getLatLng(
  device: any
): { latitude: number; longitude: number } | null {
  if (device.latitude && device.longitude) {
    return { latitude: device.latitude, longitude: device.longitude };
  } else if (device.homeLatitude && device.homeLongitude) {
    return { latitude: device.homeLatitude, longitude: device.homeLongitude };
  }
  return null;
}

/**
 * Conditionally add a elements to an array literal.
 *
 * @example
 * const arr = [...insertIf(true, "a"), "b"];
 */
export function insertIf(condition: boolean, ...elements: any) {
  return condition ? elements : [];
}

export function getNumericEnumValues<T>(enumObject: T): T[keyof T][] {
  return Object.values(enumObject).filter((val) => typeof val === "number");
}

export function isDefined<T>(val: T | null | undefined): val is T {
  return val !== null && val !== undefined;
}
